import React, { Component, useCallback, useRef, useState, useEffect } from "react"
import useOutsideClick from "../../hooks/useOutsideClick"
import { useStaticQuery, graphql, Link } from "gatsby"
import $ from "jquery"
import MobileFilterModal from "../../components/ContentBuilder/Modal/MobileFilterModal"
import { connect } from "react-redux"
import { setSort } from "../../state/sort"
import { isLoggedIn } from "../../services/auth"
import { setSearchValue } from "../../state/search"

const MainDesignFilter = data => {
  const [show, setShow] = useState(false)
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  var checkURL = url.substring(0, url.length - 1)
  const segment = checkURL.substring(checkURL.lastIndexOf("/") + 1)
  var formAction = '/search/';
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  var keyword = ''
  if(params.get("keyword")){
        keyword = params.get("keyword").toLowerCase()
    }

  var category_default_label = "EXPLORE BIRDY"
  const categoriesForLabels = [
    { name: "STYLE", URLSegment: "style" },
    { name: "HOME DESIGNS", URLSegment: "home-designs" },
    { name: "FACADES", URLSegment: "facades" },
    //{ name: "LAND", URLSegment: "land" },
    { name: "FINISHES & FIXTURES", URLSegment: "finishes-fixtures" },
    { name: "CABINETRY", URLSegment: "cabinetry" },
    { name: "FURNITURE", URLSegment: "furniture" },
  ]

  const breadcrumb = {
    style: "Style",
    "home-designs": "Home Designs",
    facades: "Facades",
    //land: "Land",
    "finishes-fixtures": "Finishes & Fixtures",
    cabinetry: "Cabinetry",
    furniture: "Furniture",
  }
  const globalConfig = useStaticQuery(graphql`
    {
      silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
            hidePricingGlobally
        }
      }
    }
  `)
  const globalConfigObject = globalConfig.silverStripeDataObject.UndigitalGlobalConfig
  const hidePricingGlobally = globalConfigObject.hidePricingGlobally
  categoriesForLabels.forEach(categoryLabel => {
    let url = categoryLabel.URLSegment
    let categoryName = categoryLabel.name
    if (segment === url) {
      category_default_label = "BROWSE " + categoryName
      return
    }
  })

  const handleSearchChange = useCallback(e => {
    data.dispatch(setSearchValue(e.target.value))
  }, [data.dispatch])
  
  const [logged, setLogged] = useState(false)
    useEffect(() => {
        const isLogged = isLoggedIn()
        setLogged(isLogged)
    }, []);
  return (
    <>
      {data.page !== "homepage" ? (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            {segment !== "explore" && (
              <li className="breadcrumb-item active" aria-current="page">
                {breadcrumb[segment]}
              </li>
            )}
          </ol>
        </nav>
      ) : (
        ""
      )}

      <div className="row justify-content-md-center">
        <div className={`col col-lg-10 mb-4 text-center${data.title ? ' mt-5' : ''}`}>
          <h1>{data.title ? data.title : category_default_label}</h1>
          <div
            className="intro-text"
            dangerouslySetInnerHTML={{ __html: data.intro }}
          />
        </div>
      </div>
      {data.page !== "homepage" ? (
        <div className="explore-functional row mt-15">
          <div className="col-lg-6 order-lg-3">
            <form
              method="get"
              id="designsfilter"
              action={formAction}
              className="search-box"
            >
                <input
                  onChange={handleSearchChange}
                  defaultValue={keyword}
                  name="keyword"
                  className="filter-text"
                  placeholder="SEARCH"
                />
              <div className="filter-icon">
                <img src="/img/explore/search.svg" />
              </div>
            </form>
          </div>
          {data.pageName !== 'cabinetry' && (
            <div className="col order-lg-1 d-block d-lg-none">
              <button
                type="button"
                className="btn btn-outline-black btn-block"
                onClick={() => setShow(true)}
              >
                FILTER
                <img
                  src="/img/explore/sort.svg"
                  width="12"
                  height="12"
                  className="ml-2"
                />
              </button>
            </div>
          )}
          <div className="col col-lg-3 order-lg-2">
            <RenderSort dispatch={data.dispatch} hidePricingGlobally={hidePricingGlobally} />
          </div>

          <div className="col-12 col-lg-3 order-lg-1 d-none d-lg-block">
            <div className="sub-title">
              <span>products</span>
            </div>
          </div>
        </div>
      ) : (      
        <div className={`explore-functional row mt-15 justify-content-center${!logged ? ' d-none' : ''}`}>
          <div className="col-xl-6 col-lg-10">
            <form
              method="get"
              id="designsfilter"
              action={formAction}
              className="search-box d-flex"
            >
                <input
                  onChange={handleSearchChange}
                  defaultValue={keyword}
                  name="keyword"
                  className="filter-text"
                  placeholder="SEARCH"
                />
              <div className="filter-icon">
                <img src="/img/explore/search.svg" />
              </div>
            </form>
          </div>
        </div>        
      )}
      <MobileFilterModal show={show} handleClose={setShow} />
    </>
  )
}

export default connect(null, null)(MainDesignFilter)

const RenderSort = ({ dispatch, hidePricingGlobally }) => {  
  const [show, setShow] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    setShow(false)
  })

  const sortClickHandler = useCallback(val => {
    dispatch(setSort(val))
    setShow(false)
  }, [])

  const addSortQuery = (key, value) => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, "", "?sort=" + value)
      window.location.reload()
    }
  }

  return (
    <div
      className="explore-functional-button sort-button sort-filter-relative"
      ref={ref}
      onClick={() => setShow(true)}
    >
      <div className="filter-text">SORT</div>
      <div className={`filter-icon${show ? " rotate-180deg" : ""}`}>
        <img src="/img/explore/sort.svg" />
      </div>
      <div className={`sort-filter-container${show ? " show" : ""}`}>
        <div
          onClick={() => sortClickHandler('popular')}
          className="sort-filter-item"
        >
          <div data-sort="popular">MOST POPULAR</div>
        </div>
        <div
          onClick={() => sortClickHandler('latest')}
          className="sort-filter-item"
        >
          <div data-sort="latest">NEW ARRIVALS</div>
        </div>
        <div
          onClick={() => sortClickHandler('az')}
          className="sort-filter-item"
        >
          <div>A-Z</div>
        </div>
        {hidePricingGlobally == 0 && (
          <>
            <div
              onClick={() => sortClickHandler('pricehightolow')}
              className="sort-filter-item"
            >
              <div data-sort="price">HIGHEST PRICE</div>
            </div>
            <div
              onClick={() => sortClickHandler('pricelowtohigh')}
              className="sort-filter-item"
            >
              <div data-sort="price">LOWEST PRICE</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
