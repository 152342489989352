import React, { useCallback, useMemo, useState } from "react"
import { connect } from 'react-redux'
import { removeFilter } from "../../../state/filter"
import { setSort } from "../../../state/sort"
import FilterItem from "./FilterItem"

const FilterResult = ({ activeFilters, sort, dispatch }) => {
  const removeItem = useCallback(name => {
    dispatch(removeFilter(name))
  }, [dispatch])

  const removeSort = useCallback(() => {
    dispatch(setSort(''))
  }, [dispatch])

  const renderItems = useMemo(() => {
    return (activeFilters.length + sort.length) > 0 && <ul className="filters-list">
      {sort.length > 0 && (
        <FilterItem
          filter={{ label: sort }}
          removeItem={removeSort}
        ></FilterItem>
      )}
      {activeFilters.map((filter, i) => (
        <FilterItem
          key={i}
          filter={filter}
          removeItem={removeItem}
        ></FilterItem>
      ))}
    </ul>
  }, [activeFilters, sort])

  return (
    <div className="filters">
      {renderItems}
    </div>
  )
}

export default connect(state => ({
  activeFilters: state.filter.active,
  sort: state.sort.current
}), null)(FilterResult)
