import React, { useState } from 'react'
import SlideToggle from 'react-slide-toggle'
import InputRange from 'react-input-range'
import { MAX_BATH_ROOMS, MAX_BED_ROOMS, MAX_GARAGES, MAX_LIVING_SPACES } from '../../constants/filter';

const StyleFilter = data => {
  const [price, setPrice] = useState({
    value: {
      min: 10,
      max: 300
    }
  });
  const [width, setWidth] = useState({
    value: {
      min: 10,
      max: 300
    }
  });
  const [height, setHeight] = useState({
    value: {
      min: 10,
      max: 300
    }
  });
  let isClose = false;
  const [isActive, setActive] = useState(isClose);
  const handleToggle = () => {
    setActive(!isClose);
  };

  let filterType = data.type;
  let allFilters = data.all;

  var showFilter = true;
  var list = false;
  var single = false;
  var title, type, fieldname;
  var isRangeSlider = false;
  var isMetterSlider = false;
  var beds = [];
  var bath = [];
  var garages = [];
  var livings = [];
  var hidePricingGlobally = data.globalConfig.hidePricingGlobally;

  var max = 6;
  var maxBedrooms = MAX_BED_ROOMS || data.globalConfig.maxBedrooms;
  var maxBathrooms = MAX_BATH_ROOMS || data.globalConfig.maxBathrooms;
  var maxGarages = MAX_GARAGES || data.globalConfig.maxGarages;
  var maxLivingSpaces = MAX_LIVING_SPACES || data.globalConfig.maxLivingSpaces;
  maxBedrooms = maxBedrooms > max ? max : maxBedrooms;
  maxBathrooms = maxBathrooms > max ? max : maxBathrooms;
  maxGarages = maxGarages > max ? max : maxGarages;
  maxLivingSpaces = maxLivingSpaces > max ? max : maxLivingSpaces;
  for (let i = 1; i <= maxBedrooms; i++) {
    let is_many = i > 1 ? 's' : '';
    beds[i] = i + ' ' + 'Bedroom' + is_many;
  }
  if (maxBedrooms >= max) {
    beds[maxBedrooms] = maxBedrooms + '+ Bedrooms';
  }

  for (let i = 1; i <= maxBathrooms; i++) {
    let is_many = i > 1 ? 's' : '';
    bath[i] = i + ' ' + 'Bathroom' + is_many;
  }
  if (maxBathrooms >= max) {
    bath[maxBathrooms] = maxBathrooms + '+ Bathrooms'
  }

  for (let i = 1; i <= maxGarages; i++) {
    let is_many = i > 1 ? 's' : '';
    garages[i] = i + ' ' + 'Garage' + is_many;
  }
  if (maxGarages >= max) {
    garages[maxGarages] = maxGarages + '+ Garages'
  }

  for (let i = 1; i <= maxLivingSpaces; i++) {
    let is_many = i > 1 ? 's' : '';
    livings[i] = i + ' ' + 'Living Space' + is_many;
  }
  if (maxLivingSpaces >= max) {
    livings[maxLivingSpaces] = maxLivingSpaces + '+ Living Spaces';
  }

  if (filterType === 'Style') {
    fieldname = 'style';
    title = 'STYLE';
    type = 'checkbox';
    list = allFilters.Style;
  } else if (filterType === 'HomeType') {
    fieldname = 'hometype';
    title = 'HOUSE TYPE'
    type = 'checkbox';
    list = allFilters.HomeType;
  } else if (filterType === 'Room') {
    fieldname = 'room';
    title = 'AMENITY';
    type = 'checkbox';
    list = allFilters.Room;
    //isClose = true;
  } else if (filterType === 'Designer') {
    fieldname = 'designer';
    title = 'DESIGNER';
    type = 'checkbox';
    list = allFilters.Designer;
  } else if (filterType === 'Storeys') {
    fieldname = 'storeys';
    title = 'STOREYS';
    type = 'checkbox';
    list = allFilters.Storeys;
  } else if (filterType === 'Category') {
    fieldname = 'categories';
    title = 'Categories';
    type = 'checkbox';
    list = allFilters.Categories;
  } else if (filterType === 'Finish') {
    fieldname = 'finishes';
    title = 'Finishes';
    type = 'checkbox';
    list = allFilters.Finishes;
  } else if (filterType === 'Brand') {
    fieldname = 'brand';
    title = 'BRAND';
    type = 'checkbox';
    list = allFilters.Brand;
    //isClose = true;
  } else if (filterType === 'PriceSlider') {
    if (hidePricingGlobally === 0) {
      fieldname = 'price';
      title = 'BUDGETS';
      type = 'rangeslider';
      isRangeSlider = true;
    } else {
      showFilter = false;
    }

  } else if (filterType === 'Bed') {
    fieldname = 'bed';
    title = 'BEDROOMS';
    type = 'checkbox';
    list = beds;
    //isClose = true;
  } else if (filterType === 'Bath') {
    fieldname = 'bath';
    title = 'BATHROOMS';
    type = 'checkbox';
    list = bath;
    //isClose = true;
  } else if (filterType === 'Garage') {
    fieldname = 'garage';
    title = 'GARAGE';
    type = 'checkbox';
    list = garages;
    //isClose = true;
  } else if (filterType === 'LivingSpaces') {
    fieldname = 'living'
    title = 'LIVING SPACE';
    type = 'checkbox';
    list = livings;
    //isClose = true;
  } else if (filterType === 'LotWidth') {
    //showFilter = false;
    fieldname = 'lotwidth';
    title = 'Lot Width';
    type = 'rangeslider';
    isRangeSlider = true;
  } else if (filterType === 'AddressSearch') {
    single = true;
    fieldname = 'address';
    title = 'ADDRESS';
    type = 'textbox';
  } else if (filterType === 'LandArea') {
    fieldname = 'landarea';
    title = 'LAND AREA';
    type = 'rangeslider';
    isRangeSlider = true;
  } else if (filterType === 'LandSize') {
    fieldname = 'landsize';
    title = 'LAND SIZE';
    type = 'rangeslider';
    isRangeSlider = true;
    isMetterSlider = true;
  } else if (filterType === 'SubCategory') {
    showFilter = false;
    fieldname = 'subcategory';
    title = 'SUB CATEGORY';
    type = 'checkbox';
  } else if (filterType === 'Colour') {
    fieldname = 'colour';
    title = 'COLOUR';
    type = 'checkbox';
    list = allFilters.Colour;
    //// isClose = true;
  }

  const className = data.isMobile ? 'filter-modal-container filter-modal-container-custom mt-15' : 'filter-modal-container filter-modal-container-custom mt-15 d-none d-md-block'

  return (
    <>
      {showFilter && (
        <div className={className}>
          {isRangeSlider && (
            <SlideToggle collapsed={isClose}>
              {({ toggle, setCollapsibleElement, toggleState }) => (
                <div className={`widget widget-collapsible${toggleState == 'EXPANDED' ? ' active' : ''}`}>
                  <h3 className="widget-title">
                    <div
                      className={`wiget-title-name${toggleState == 'EXPANDED' ? ' active' : ''}`}
                      onClick={e => {
                        toggle(e)
                        handleToggle()
                        e.preventDefault()
                      }}
                    >
                      {title}
                    </div>
                  </h3>
                  <div className={`widget-body${toggleState == 'EXPANDED' ? ' active' : ''}`} ref={setCollapsibleElement}>
                    <div className="custom-input-range">
                      {isMetterSlider ?
                        <>
                          <div className="label-wrap">
                            <label>Width</label>
                            <InputRange
                              formatLabel={width => `${width}m`}
                              maxValue={1000}
                              minValue={0}
                              step={10}
                              value={width.value}
                              onChange={e => setWidth({ value: e })}
                            />
                          </div>

                          <div className="label-wrap">
                            <label>Height</label>
                            <InputRange
                              formatLabel={height => `${height}m`}
                              maxValue={1000}
                              minValue={0}
                              step={10}
                              value={height.value}
                              onChange={e => setHeight({ value: e })}
                            />
                          </div>
                        </>
                        :
                        <div className="label-wrap">
                          <InputRange
                            formatLabel={price => `$${price}K`}
                            maxValue={1000}
                            minValue={0}
                            step={10}
                            value={price.value}
                            onChange={e => setPrice({ value: e })}
                          />
                        </div>
                      }
                      <input type="hidden" name={fieldname} />
                    </div>
                  </div>
                </div>
              )}
            </SlideToggle>
          )}
          {list && (
            <SlideToggle collapsed={isClose}>
              {({ toggle, setCollapsibleElement, toggleState }) => (
                <div className="widget widget-collapsible">
                  <h3 className="widget-title">
                    <div
                      className={`wiget-title-name${toggleState == 'EXPANDED' ? ' active' : ''}`}
                      onClick={e => {
                        toggle(e)
                        handleToggle()
                        e.preventDefault()
                      }}
                    >
                      {title}
                    </div>
                  </h3>
                  <div className="widget-body" ref={setCollapsibleElement}>
                    {(() => {
                      switch (type) {
                        case 'checkbox':
                          return list.map((name, id) => {
                            const isChecked = data.activeFilters.some(f => f.type === fieldname && f.value === id)
                            const itemFilter = {
                              type: fieldname,
                              label: name,
                              value: id
                            }
                            const shouldRender = data.onRenderItem(itemFilter)
                            return shouldRender && (
                              <div key={`filter${name}${id}`} className="custom-control custom-checkbox mb-2">
                                <input name={fieldname} checked={isChecked} type="checkbox" className="custom-control-input" value={id} id={`${filterType}${id}`} onChange={e => {
                                  data.onSelectItem(itemFilter)
                                }} />
                                <label className="custom-control-label" htmlFor={`${filterType}${id}`}>{name}</label>
                              </div>
                            )
                          })
                        case 'dropdown':
                          return list.map((name, id) => (
                            <div key={`filter${name}${id}`} className="mb-2 home-type-item cursor-pointer">
                              <div htmlFor={`${filterType}${id}`}>{name}</div>
                            </div>
                          ))
                        case 'textbox':
                          return (
                            <div className="custom-control mb-2">
                              <input name={fieldname} type="text" className="custom-control-input" value="" id={`${fieldname}`} />
                              <label className="custom-control-label" htmlFor={`${fieldname}`}>{title}</label>
                            </div>
                          )
                        default:
                          return
                      }
                    })()}
                  </div>
                </div>
              )}
            </SlideToggle>
          )}
          {single && (
            <SlideToggle collapsed={isClose}>
              {({ toggle, setCollapsibleElement, toggleState }) => (
                <div className="widget widget-collapsible">
                  <h3 className="widget-title">
                    <div
                      className={`wiget-title-name${toggleState == 'EXPANDED' ? ' active' : ''}`}
                      onClick={e => {
                        toggle(e)
                        handleToggle()
                        e.preventDefault()
                      }}
                    >
                      {title}
                    </div>
                  </h3>
                  <div className="widget-body" ref={setCollapsibleElement}>
                    {type === 'textbox' && (
                      <div className="form-group mb-2">
                        <input name={fieldname} type="text" className="form-control" id={`${fieldname}`} />
                        <label className="custom-control-label" htmlFor={`${fieldname}`}>{title}</label>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </SlideToggle>
          )}
        </div>
      )}
    </>
  )
}

export default StyleFilter